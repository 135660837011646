.product-device {
    position: absolute;
    right: 10%;
    bottom: -30%;
    width: 300px;
    height: 540px;
    background-color: #333;
    border-radius: 21px;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);

    &::before {
        position: absolute;
        top: 10%;
        right: 10px;
        bottom: 10%;
        left: 10px;
        content: '';
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
    }
}
.product-device-2 {
    top: -25%;
    right: auto;
    bottom: 0;
    left: 5%;
    background-color: #e5e5e5;
}

@media (min-width: 576px) {
    .featurette-divider {
        margin: 5rem 0 !important;
    }
}
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.05rem;

    @media (min-width: 40em) {
        font-size: 50px;
    }
}
@media (max-width: 767px) {
    .featurette-logo {
        display: block;
        width: 50%;
        margin: auto;
    }
}
